define(['accordionWidget', 'accordionWidgetContainer', 'app'], function(accordionWidget, accordionCollection, app) {
  const helpCentre = function() {
    var comp = {};

    comp.select = {
      accordion: '[data-accordion]',
      accordionTitle: '.helpCentre_accordionTitleWrapper',
      accordionContent: '.helpCentre_accordionContent',
      highlight: '.helpCentre_highlight',
      menu: '.helpCentre_menu',
      menuList: '.helpCentre_menuList',
      menuTitle: '.helpCentre_menuTitle',
      search: '.helpCentre_searchInput',
      section: '.helpCentre_section',
      sectionTitle: '.helpCentre_sectionTitle',
      shown: '[data-show=true]',
      accordionFAQ: '.helpCentre_accordionTitle'
    };

    comp.attr = {
      section: 'data-section',
      show: 'data-show',
      searching: 'data-searching',
      found: 'data-found',
      active: 'data-active',
      tracking: 'data-tracking',
      aria: 'aria-hidden'
    };

    comp.classes = {
      highlight: 'helpCentre_highlight',
      menuItem: 'helpCentre_menuItem',
      section: 'helpCentre_section'
    };

    comp.init = function(el) {
      comp.el = el;
      comp.menu = comp.el.querySelector(comp.select.menu);
      comp.menuTitle = comp.el.querySelector(comp.select.menuTitle);
      comp.menuList = comp.el.querySelector(comp.select.menuList);
      comp.accordions = Array.from(el.querySelectorAll(comp.select.accordion));
      comp.sections = Array.from(el.querySelectorAll(comp.select.section));
      comp.search = el.querySelector(comp.select.search);

      comp.lastSection = 0;
      comp.menuItems = [];
      comp.bindTrackingEvents();

      comp.setup();
    };

    comp.sendTrackingEvents = function(event) {
      var element = event.currentTarget;
      var faqTitle = element.querySelector(comp.select.accordionFAQ).innerText;
      var aboutToExpand = element.getAttribute(comp.attr.aria);
      // send tracking events
      if (aboutToExpand === 'true') {
        app.publish('tracking/record', 'Help Centre', 'Clicked', faqTitle);
      }
    };

    comp.bindTrackingEvents = function() {
      comp.accordions.map(function(element) {
        element.addEventListener('click', comp.sendTrackingEvents);
      });
    };

    comp.setup = function() {
      comp.search.addEventListener('keyup', comp.onchange);

      // if there are no sections, no reason to do anything else
      if (!comp.sections.length) {
        return;
      }

      // bind an accordion for every question
      comp.accordions = comp.accordions.map(function(element) {
        var component = accordionWidget();

        component.config.selectors = {
          title: comp.select.accordionTitle,
          content: comp.select.accordionContent
        };

        return component.init(element);
      });

      // add all accordions to a collection
      accordionCollection().init(comp.el);

      // check if there is a menu, otherwise it breaks
      if (!comp.menu) {
        return;
      }

      // populate menu
      comp.menuItems = comp.sections.map(function(section, index) {
        var listItem = document.createElement('li');
        var item = document.createElement('a');
        var title = section.querySelector(comp.select.sectionTitle);

        section.id = `${comp.classes.section}-${index}`;
        item.className = comp.classes.menuItem;
        item.href = `#${section.id}`;
        item.innerText = title.innerText;
        item.setAttribute(comp.attr.section, index);
        item.setAttribute(comp.attr.tracking, title.innerText);

        listItem.appendChild(item);
        comp.menuList.appendChild(listItem);
        item.addEventListener('click', comp.onclick);

        return item;
      });

      comp.menuTitle.addEventListener('click', comp.toggle);
      comp.menuItems[0].setAttribute(comp.attr.active, 'true');

      // hide all sections but the first
      comp.sections.map(s => s.setAttribute(comp.attr.show, 'false'));
      comp.sections[0].setAttribute(comp.attr.show, 'true');
    };

    comp.onclick = function(ev) {
      ev.preventDefault();

      var el = ev.target;
      var index = parseInt(el.getAttribute(comp.attr.section));
      var trackedTopic = el.getAttribute(comp.attr.tracking);

      if (isNaN(index)) {
        return false;
      }

      if (!comp.sections[index]) {
        return false;
      }

      if (comp.el.getAttribute(comp.attr.searching) === 'true') {
        comp.dehighlight();

        comp.el.removeAttribute(comp.attr.found);
        comp.el.setAttribute(comp.attr.searching, 'false');

        // hide all sections, but the one selected
        comp.sections.map(s => s.setAttribute(comp.attr.show, 'false'));
        comp.sections[comp.lastSection].setAttribute(comp.attr.show, 'true');
      }

      // don't toggle the last section if it's the same as the new one to avoid flickering
      if (comp.lastSection === index) {
        return false;
      }

      // hide last section, show new section and display section title
      comp.sections[comp.lastSection].setAttribute(comp.attr.show, 'false');
      comp.sections[index].setAttribute(comp.attr.show, 'true');

      // set active menu items
      comp.menuItems[comp.lastSection].removeAttribute(comp.attr.active);
      comp.menuItems[index].setAttribute(comp.attr.active, 'true');

      comp.lastSection = index;

      // send tracking events
      app.publish('tracking/record', 'Help Centre', 'Clicked', trackedTopic);

      return true;
    };

    comp.toggle = function() {
      var show = comp.menu.getAttribute(comp.attr.show) !== 'true';
      comp.menu.setAttribute(comp.attr.show, show.toString());
    };

    comp.onchange = function() {
      comp.dehighlight();

      // get searching terms without any special characters
      var input = (comp.search.value || '').replace(/[^\w\s?']/gi, '');
      var terms = input.split(' ').filter(x => x.length > 0);
      var found = false;

      // don't need searching for 2 or less characters
      if (input.length < 3) {
        comp.el.removeAttribute(comp.attr.found);
        comp.el.setAttribute(comp.attr.searching, 'false');

        // hide all sections, but the one selected
        comp.sections.map(s => s.setAttribute(comp.attr.show, 'false'));
        comp.sections[comp.lastSection].setAttribute(comp.attr.show, 'true');

        return false;
      }

      // highlight text
      comp.accordions.map(function(acrd) {
        // only show the question if it's highlighted
        var highlighted = comp.highlight(acrd.title, terms);
        // highlighted terms should be more than half of the terms so ui isn't cluttered
        var shown = (highlighted >= terms.length / 2);

        acrd.element.setAttribute(comp.attr.show, shown.toString());
        found = found || shown;
      });

      // toggle sections
      comp.sections.map(function(section) {
        // only show sections which have the title or content highlighted
        var shown = section.querySelectorAll(comp.select.shown).length > 0;
        section.setAttribute(comp.attr.show, shown.toString());
      });

      comp.el.setAttribute(comp.attr.searching, 'true');
      comp.el.setAttribute(comp.attr.found, found.toString());

      return true;
    };

    comp.dehighlight = function() {
      var el;
      var highlights = Array.from(comp.el.querySelectorAll(comp.select.highlight));

      while (highlights.length) {
        el = highlights.shift();
        el.outerHTML = el.innerHTML;
      }
    };

    comp.highlight = function(el, terms) {
      var count = 0;

      terms.map(function(term) {
        // match the term such that it's not inside a tag
        // assuming term is 'foo', the regex will be /(>[^<]*?|^[^<]*?)(foo)([^<]*)/i
        // this should match 'foo', '<span>foo</span>', but not '<span class="foo">bar</span>'
        var search = new RegExp('(>[^<]*?|^[^<]*?)(' + term + ')([^<]*?)', 'i');
        // $2 is our term and we wrap it inside a span element
        var replace = `$1<span class="${comp.classes.highlight}">$2</span>$3`;

        // increment count if term was found
        count += search.test(el.innerHTML);
        el.innerHTML = el.innerHTML.replace(search, replace);
      });

      return count;
    };

    return comp;
  };

  return helpCentre;
});
